import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/** note: sub-menu only appear when children.length>=1
 *  detail see  https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 **/

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    roles: ['admin','editor']    will control the page roles (you can set multiple roles)
    title: 'title'               the name show in sub-menu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if true, the page will no be cached(default is false)
    breadcrumb: false            if false, the item will hidden in breadcrumb(default is true)
    affix: true                  if true, the tag will affix in the tags-view
  }
**/

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 * */
export var constantRoutes = [{
  path: '/redirect',
  hidden: true,
  component: Layout,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/404',
  component: function component() {
    return import('@/components/ErrorPage/404');
  },
  hidden: true
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export var asyncRoutes = [{
  path: '/',
  redirect: '/index',
  name: 'index',
  component: Layout,
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    meta: {
      title: '首页',
      icon: 'list',
      noCache: true
    }
  }]
}, {
  path: '/job',
  component: Layout,
  meta: {
    title: '作业管理',
    icon: 'script'
  },
  children: [{
    path: 'job',
    name: 'job.job',
    component: function component() {
      return import('@/views/job/Job');
    },
    meta: {
      title: '作业执行',
      icon: 'launch',
      noCache: true
    }
  }, {
    path: 'fileTransfer',
    name: 'job.fileTransfer',
    component: function component() {
      return import('@/views/job/FileTransfer');
    },
    meta: {
      title: '快速分发文件',
      icon: 'file',
      noCache: true
    }
  }, {
    path: 'jobList',
    name: 'job.list',
    component: function component() {
      return import('@/views/job/JobList');
    },
    meta: {
      title: '常用作业',
      icon: 'list',
      noCache: true
    }
  }, {
    path: 'newJob',
    name: 'job.new',
    component: function component() {
      return import('@/views/job/JobCreate');
    },
    meta: {
      title: '新建作业',
      icon: 'file',
      noCache: true
    }
  }, {
    path: 'launch/:id',
    hidden: true,
    name: 'job.launch',
    component: function component() {
      return import('@/views/job/LaunchJob');
    },
    meta: {
      title: '执行作业',
      icon: 'file',
      noCache: true
    }
  }, {
    path: 'edit/:id',
    hidden: true,
    name: 'job.edit',
    component: function component() {
      return import('@/views/job/JobCreate');
    },
    meta: {
      title: '编辑作业',
      icon: 'file',
      noCache: true
    }
  }, {
    path: 'cron',
    name: 'job.cron',
    component: function component() {
      return import('@/views/job/CronJob');
    },
    meta: {
      title: '定时作业',
      icon: 'calendar',
      noCache: true
    }
  }]
}, {
  path: '/history',
  component: Layout,
  meta: {
    title: '执行历史',
    icon: 'history'
  },
  children: [{
    path: 'job',
    name: 'history.job',
    component: function component() {
      return import('@/views/history/JobHistory');
    },
    meta: {
      title: '作业执行历史',
      icon: 'history',
      noCache: true
    }
  }, {
    path: 'detail/:id',
    hidden: true,
    component: function component() {
      return import('@/views/history/JobDetail');
    },
    meta: {
      title: '执行详情',
      noCache: true
    }
  }, {
    path: 'action',
    name: 'history.action',
    component: function component() {
      return import('@/views/history/ActionHistory');
    },
    meta: {
      title: '操作历史',
      icon: 'security',
      noCache: true
    }
  }]
}, {
  path: '/settings',
  component: Layout,
  meta: {
    title: '系统设置',
    icon: 'gear'
  },
  children: [{
    path: 'account',
    name: 'job.account',
    component: function component() {
      return import('@/views/job/Account');
    },
    meta: {
      title: '帐户配置',
      icon: 'user',
      noCache: true
    }
  }, {
    path: 'notify_conf',
    name: 'job.notify_conf',
    component: function component() {
      return import('@/views/job/NotifyConf');
    },
    meta: {
      title: '通知配置',
      icon: 'server',
      noCache: true
    }
  }, {
    path: 'category',
    name: 'job.category',
    component: function component() {
      return import('@/views/job/Category');
    },
    meta: {
      title: '分类配置',
      icon: 'folder',
      noCache: true
    }
  }]
}, {
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;